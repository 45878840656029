<template>
  <form @submit.prevent class="w-100 h-100">
    <div class="row mx-0 h-100 justify-content-center">
      <div class="col-12 px-0 bg_overlay">
        <div class="row m-0 justify-content-center">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-auto text-center p-0 mb-3 card_heading px-3 py-2">
                DONOR DETAILS FINDER
              </div>
              <div class="col-12 p-0 my-3" v-if="page !== 3">
                <div class="row justify-content-center">
                  <div class="col-auto p-0 relative text-center">
                    <div class="row mx-0 mb-2">
                      <div class="col-auto ps-0 pe-2 text-center">
                        <div class="wizard_divider opacity">
                        </div>
                      </div>
                      <div class="col-auto p-0 text-center">
                        <div class="wizard_step" :class="{active: page === 0}">
                          <div class="row m-0 h-100 align-items-center">
                            <div class="col-12 p-0 text-center">
                              1
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto pe-0 ps-2 text-center">
                        <div class="wizard_divider">
                        </div>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-12 p-0 text-center small_font">
                        Your Details
                      </div>
                    </div>
                  </div>
                  <div class="col-auto p-0 relative text-center">
                    <div class="row mx-0 mb-2">
                      <div class="col-auto ps-0 pe-2 text-center">
                        <div class="wizard_divider">
                        </div>
                      </div>
                      <div class="col-auto p-0 text-center">
                        <div class="wizard_step" :class="{active: page === 1}">
                          <div class="row m-0 h-100 align-items-center">
                            <div class="col-12 p-0 text-center">
                              2
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto pe-0 ps-2 text-center">
                        <div class="wizard_divider">
                        </div>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-12 p-0 text-center small_font">
                        Address
                      </div>
                    </div>
                  </div>
                  <div class="col-auto p-0 relative text-center">
                    <div class="row mx-0 mb-2">
                      <div class="col-auto ps-0 pe-2 text-center">
                        <div class="wizard_divider">
                        </div>
                      </div>
                      <div class="col-auto p-0 text-center">
                        <div class="wizard_step" :class="{active: page === 2}">
                          <div class="row m-0 h-100 align-items-center">
                            <div class="col-12 p-0 text-center">
                              3
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto pe-0 ps-2 text-center">
                        <div class="wizard_divider opacity">
                        </div>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-12 p-0 text-center small_font">
                        Search
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-10 p-0">
                <div class="row m-0" v-if="page === 0">
                  <div class="col-12 p-0 green-text text-center mb-3 bigger_font">
                    Search to see if you're already registered as a donor to retrieve your login details, donation history and more.
                  </div>
                  <div class="col-12 green-text text-center mb-3">
                    Please enter the name and email address that may have been used in a prior donation.
                  </div>
                  <div class="col-6 mb-3">
                    <TextField type="text" label="First Name:" placeholder="First Name" v-model="firstName" id="firstName" color="gold" :errors="v$.firstName.$errors" :hasErrors="v$.firstName.$errors.length" />
                  </div>
                  <div class="col-6 mb-3">
                    <TextField type="text" label="Last Name:" placeholder="Last Name" v-model="lastName" id="lastName" color="gold" :errors="v$.lastName.$errors" :hasErrors="v$.lastName.$errors.length" />
                  </div>
                  <div class="col-12 mb-3">
                    <TextField type="email" label="Email Address:" placeholder="email@mail.com" v-model="username" id="username" color="gold" autocomplete="username" :errors="v$.username.$errors" :hasErrors="v$.username.$errors.length" />
                  </div>
                </div>
                <div class="row m-0" v-if="page === 1">
                  <div class="col-12 green-text text-center mb-3">
                    Please enter an address that may have been used in a prior donation.
                  </div>
                  <div class="col-12 mb-3">
                    <MapAutocomplete
                      @inputChanged="updateAddress"
                      placeholder="Search Address"
                      id="address"
                      :value="primaryAddress"
                      :map="true"
                      color="outline-green"
                    />
                  </div>
                  <div class="col-12 mb-3">
                    <TextField type="text" label="Address Line 1:" placeholder="Address Line 1" v-model="primaryAddress.addressLine1" id="addressLine1" color="gold" />
                  </div>
                  <div class="col-12 mb-3">
                    <TextField type="text" label="Address Line 2:" placeholder="Address Line 2" v-model="primaryAddress.addressLine2" id="addressLine2" color="gold" />
                  </div>
                  <div class="col-6 mb-3">
                    <TextField type="text" label="Town/City:" placeholder="Town/City" v-model="primaryAddress.town" id="town" color="gold" />
                  </div>
                  <div class="col-6 mb-3">
                    <TextField type="text" label="Province:" placeholder="Province" v-model="primaryAddress.province" id="province" color="gold" />
                  </div>
                  <div class="col-6 mb-3">
                    <TextField type="text" label="Postal Code:" placeholder="Postal Code" v-model="primaryAddress.postalCode" id="postalCode" color="gold" />
                  </div>
                  <div class="col-6 mb-3">
                    <TextField type="text" label="Country:" placeholder="Country" v-model="primaryAddress.country.description" id="country" color="gold" />
                  </div>
                </div>
                <div class="row m-0" v-if="page === 2">
                  <SearchingAnimation />
                  <div class="col-12 mb-5 text-center green-text biggest_font">
                    Searching...
                  </div>
                </div>
                <div class="row m-0" v-if="page === 3">
                  <div class="col-12  mb-3" >
                    <div class="row justify-content-center" v-if="searchResults.donorStatus === 'MATCHED_EMAIL_SENT'">
                      <div class="col-5">
                        <img src="@/components/animations/tickAnimation.gif" v-if="isApp" class="img-fluid" />
                        <TickAnimation v-else />
                      </div>
                      <div class="col-12 text-center biggest_font bold green-text mb-3">
                        Donor Profile Found
                      </div>
                      <div class="col-12 text-center bigger_font green-text-dark mb-3">
                        Your donor profile has been matched and your login details have been sent to your registered email: <b>{{username}}</b>
                      </div>
                    </div>
                    <div class="row justify-content-center" v-else-if="searchResults.donorStatus === 'EMAIL_ADDRESS_REQUIRED'">
                      <div class="col-5">
                        <ExclamationAnimation />
                      </div>
                      <div class="col-12 text-center biggest_font bold green-text mb-3">
                        Email Address Required
                      </div>
                      <div class="col-12 text-center bigger_font green-text-dark mb-3">
                        We've found your donor profile, however there is no email address registered with your profile. An email address is required to login, <b>please kindly contact our office at 01254 698771</b> to register your email.
                      </div>
                    </div>
                    <div class="row justify-content-center" v-else-if="searchResults.donorStatus === 'MULTIPLE_PROFILES_FOUND'">
                      <div class="col-5">
                        <ExclamationAnimation />
                      </div>
                      <div class="col-12 text-center biggest_font bold green-text mb-3">
                        Multiple Profiles Matched
                      </div>
                      <div class="col-12 text-center bigger_font green-text-dark mb-3">
                        Sorry, it seems there are multiple donor profiles registered under the provided details. <b>Please kindly contact our office at 01254 698771</b> to resolve this issue.
                      </div>
                    </div>
                    <div class="row justify-content-center " v-else-if="searchResults.donorStatus === 'PROFILE_NOT_MATCHED'">
                      <div class="col-5">
                        <ExclamationAnimation />
                      </div>
                      <div class="col-12 text-center biggest_font bold green-text mb-3">
                        Profile Not Matched
                      </div>
                      <div class="col-12 text-center bigger_font green-text-dark mb-3">
                        Sorry, we could not find a donor profile with the details provided. <b>Please try editing the details</b> provided. <b>Check any spellings</b> that maybe incorrect or <b>alternative emails</b> that may have been used in your prior donations.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 align-self-end">
        <div class="row m-0 background-green py-3 justify-content-center">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center" v-if="page !== 3">
              <div class="col-auto" v-if="page !== 0">
                <Button color="green_light" width="100" btnText="Back" icon2="arrow" @buttonClicked="back">
                  <template v-slot:iconStart>
                    <IconArrowBack color="white" />
                  </template>
                </Button>
              </div>
              <div class="col-auto">
                <Button color="green_light" width="100" btnText="Next" icon="arrow" @buttonClicked="next" v-if="page === 0">
                  <IconArrowForward color="white" />
                </Button>
                <Button color="green_light" width="100" btnText="Search" icon="arrow" @buttonClicked="search" v-if="page === 1">
                  <IconArrowForward color="white" />
                </Button>
              </div>
            </div>
            <div class="row m-0 justify-content-center" v-else>
              <div class="col-auto" v-if="searchResults.donorStatus === 'MATCHED_EMAIL_SENT'">
                <Button color="green_light" width="100" btnText="Login" icon="arrow" @buttonClicked="goToLogin">
                  <IconArrowForward color="white" />
                </Button>
              </div>
              <div class="col-auto" v-if="searchResults.donorStatus === 'EMAIL_ADDRESS_REQUIRED' || searchResults.donorStatus === 'INVALID_EMAIL' || searchResults.donorStatus === 'MULTIPLE_PROFILES_FOUND'">
                <Button color="green_light" width="100" btnText="Call Now" icon2="arrow" @buttonClicked="callNow(+441254698771)">
                  <template v-slot:iconStart>
                    <IconPhone color="white" />
                  </template>
                </Button>
              </div>
              <div class="col-12" v-if="searchResults.donorStatus === 'PROFILE_NOT_MATCHED'">
                <div class="row  m-0 justify-content-center">
                  <div class="col-auto">
                    <Button color="green_light" btnText="Edit Details" icon2="arrow" @buttonClicked="goToStart">
                      <template v-slot:iconStart>
                        <IconEdit color="white" />
                      </template>
                    </Button>
                  </div>
                  <div class="col-auto">
                    <Button color="green_light" btnText="Register Now" icon="arrow" @buttonClicked="goToRegister">
                      <IconArrowForward color="white" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    MapAutocomplete: defineAsyncComponent(() => import('@/components/MapAutocomplete.vue')),
    SearchingAnimation: defineAsyncComponent(() => import('@/components/animations/SearchingAnimation.vue')),
    TickAnimation: defineAsyncComponent(() => import('@/components/animations/TickAnimation.vue')),
    ExclamationAnimation: defineAsyncComponent(() => import('@/components/animations/ExclamationAnimation.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconArrowBack: defineAsyncComponent(() => import('@/components/icons/IconArrowBack.vue')),
    IconPhone: defineAsyncComponent(() => import('@/components/icons/IconPhone.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue'))
  },
  name: 'Details Finder',
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    },
    username: {
      required,
      email
    },
    primaryAddress: {
      addressLine1: {
        required
      },
      town: {
        required
      }
    }
  },
  data () {
    return {
      page: 0,
      firstName: '',
      lastName: '',
      username: '',
      primaryAddress: {
        addressLine1: '',
        addressLine2: '',
        town: '',
        postalCode: '',
        country: {
          countryID: null,
          description: '',
          isoCountryCode2: ''
        },
        province: ''
      },
      confirmErrors: 0,
      searchResults: {}
    }
  },
  computed: {
    ...mapGetters([
      'countries', 'isApp'
    ])
  },
  methods: {
    ...mapActions([
      'findUser'
    ]),
    async next () {
      this.v$.$touch()
      switch (this.page) {
        case 0:
          if (this.v$.firstName.$invalid || this.v$.lastName.$invalid || this.v$.username.$invalid) {
            break
          }
          this.page = this.page + 1
          return true
        case 1:
          if (this.v$.primaryAddress.addressLine1.$invalid || this.v$.primaryAddress.town.$invalid) {
            return false
          }
          this.page = this.page + 1
          return true
        default:
          this.page = this.page + 1
          return true
      }
    },
    back () {
      this.page = this.page - 1
    },
    updateAddress (val) {
      const country = this.countries.find(item => item.description === val.country)
      const address = {
        addressLine1: `${val.street_number} ${val.route}`,
        addressLine2: val.sublocality_level_1,
        town: val.locality,
        postalCode: val.postal_code,
        country: country,
        province: val.province
      }
      this.primaryAddress = address
    },
    async search () {
      this.page = 2
      const searchDetails = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.username,
        postalAddress1: this.primaryAddress.addressLine1,
        town: this.primaryAddress.town,
        countryName: this.primaryAddress.country.description,
        postalcode: this.primaryAddress.postalCode,
        province: this.primaryAddress.province
      }
      const theResult = await this.findUser(searchDetails)
      this.resultsOfSearch(theResult)
    },
    resultsOfSearch (val) {
      this.page = 3
      this.searchResults = val
    },
    goToRegister () {
      this.$emit('signup')
    },
    goToStart () {
      this.page = 0
    },
    goToLogin () {
      this.$emit('login')
    },
    callNow (val) {
      window.location.href = 'tel:' + val
    }
  }
}
</script>

<style scoped>
.card_heading {
  font-family: "Quicksand", Sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--green-color);
  border-bottom: 2px solid var(--gold-color);
}
.giftAid_text {
  font-family: "quicksand", Sans-serif;
  font-size: 1.7rem;
  color: var(--green-color-dark);
  line-height: 1.5;
  text-align: center;
}
.background-green {
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  color: #fff;
  font-weight: bold;
}
.cursor:hover {
  text-decoration: underline;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.wizard_step {
  border-radius: 50%;
  color: var(--green-color-dark);
  background-color: var(--gold-color);
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 0%);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 11px;
  width: 30px;
  height: 30px;
}
.wizard_step.active {
  background-color: var(--green-color-dark);
  color: var(--gold-color);
}
.wizard_divider {
  border-bottom: 1px solid rgba(104, 103, 103, 0.4);
  width: 15px;
  height: 16px;
}
.small_font {
  font-size: 11px;
  font-family: "quicksand_bold", Sans-serif;
}
.opacity {
  opacity: 0;
}
.consent {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.form-check {
  font-family: "quicksand_bold", Sans-serif;
  font-weight: 700;
}
.bg_overlay {
  background-image: linear-gradient(rgba(249, 250, 251, 0.1) 0%, rgba(249, 250, 251, 0.7) 10%, rgba(249, 250, 251, 1) 100%);
}
.bigger_font {
  font-size: 19px;
}
.biggest_font {
  font-size: 31px;
}
.fullcard .background-green {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
@media (min-width: 992px) {
  .fullcard .background-green {
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
  }
}
</style>
